.detailBanner {
    display: flex;
    justify-content: space-between;
    line-height: 23px;
    -webkit-font-smoothing: antialiased;
  
    > div:first-child {
      display: flex;
      > div:nth-child(2) {
        color: #333740;
        font-size: 13px !important;
        font-weight: 400;
      }
    }
  }
  
  .chevronDown {
    margin-right: 8px;
    &:before {
      content: '\F078';
      display: block;
      color: #787E8F;
      font-family: 'FontAwesome';
      font-size: 11px !important;
    }
  }
  
  .chevronRight {
    margin-right: 8px;
    &:before {
      content: '\f054';
      display: block;
      color: #787E8F;
      font-family: 'FontAwesome';
      font-size: 11px !important;
    }
  }
  
  .infoContainer {
    border-top: 1px solid #F6F6F6;
    padding-top: 7px;
  }
  
  .infoWrapper {
    line-height: 18px;
    font-size: 13px !important;
    > span:first-child {
      color: #333740;
      font-weight: 500;
    }
    >span:last-child {
      color: rgba(51, 55, 64, 0.80);
      font-weight: 400;
    }
  }
  .inputFileDetails {
    width: 100%;
    padding-top: 4px;
    margin-bottom: -13px;
    margin-top: -8px;
  }
  
  .positionContainer {
    margin-left: 10px;
    color: #333740;
    font-size: 13px !important;
    -webkit-font-smoothing: antialiased;
    > span:first-child {
      font-size: 13px !important;
      font-weight: 600;
    }
    > span:last-child {
      font-size: 12px;
      font-weight: 400;
    }
  }
  
  .removeContainer {
    color: #FF3000;
    font-size: 13px !important;
    font-weight: 400;
    cursor: pointer;
  }
  
  .externalLink {
    color: #333740;
    text-decoration: none;
  
    &:hover, &:active {
      color: #333740;
      text-decoration: none;
    }
  
    > i, svg {
      margin-right: 7px;
      color: #B3B5B9;
    }
  }
  