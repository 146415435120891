.arrowContainer {
    height: 32px;
    width: 28px;
    background: rgba(0,0,0,0.20);
    border-radius: 2px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 99;
  }
  
  .arrowRight {
    position: absolute;
    top: 56px;
    right: 0;
    &:before {
      content: '\f054';
      vertical-align: middle;
      text-align: center;
      font-family: 'FontAwesome';
      font-size: 20px;
      font-weight: 800;
    }
  }
  
  .arrowLeft {
    position: absolute;
    top: 56px;
    left: 0;
    &:before {
      content: '\f053';
      vertical-align: middle;
      text-align: center;
      font-family: 'FontAwesome';
      font-size: 20px;
      font-weight: 800;
    }
  }
  