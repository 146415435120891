.newCourse-input{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
}

.newCourse-colImage{
    padding: -1px 30px 0 30px;
    max-width: 51%;
    margin-top: -87px;
}

.newCourse-colLista{
    max-width: 40%;
    width: 40%;
}
.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }
    

.newCourse-colLista{
    margin-left: 10px;
}
.newCourse-colContent{
    background-color: white;
    box-shadow: 0 0 30px 0 #999696;
    border-radius: 15px;
    max-width: 40%;
    width: 40%;
    margin-left: 80px;
    height: 30%;
}
.newCourse-Colinput{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
}

.newCourse-Select{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 20px;
}

.newCourse-check{
    margin-top: 20px;
    margin-left: 20px;
}

.newCourse-contentSwap{
    background-color: white;
    box-shadow: 0 0 30px 0 #c0bcbc;
    border-radius: 15px;
    margin-top: 31px; 
}

.newCourse-contentSwapLi{ 
    margin-top: 10px;
    font-family: 'Montserrat';
    color: #737373;
    font-weight: 600;
    padding: 18px 0 3px 1px;
}

.newCourse-button{
    margin: 11px 0 15px 0;
    background-color: #827f81;
}

.image-logo-curso{
    width: 30%;
    margin-bottom: 20px;
}