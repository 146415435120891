.ml-auto li{
    margin-left: 20px;
}

.ml-auto li a:hover{
    color: #FFF;
}

.ml-auto li a svg {
    margin-left: 6px;
}