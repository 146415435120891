
.pickerCol{
    max-width: 300px;
    margin-bottom: 20px;
}

.selectCol{
    max-width: 300px;
    margin-top: 25px;
}

.pick{
    border-radius: 16px;
}

