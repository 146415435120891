
/* .content-big-card{
    margin-bottom: 0;
} */

.Home-container{
    border: 1px solid black;
    border-radius: 25px;   
}

.content-card{
    background-color: white;
    height: 50VH;
    box-shadow: 0 0 8px 0 #7a7a7a;
    border-radius: 15px;
    margin: 6px 19px 8px -30px;
    padding: 14px 11px 26px 29px;
    display: flex;
    flex-direction: column;
}

.card-up {
    margin-top: -110px;
}

.text-metricas {
    color: #5d5e61;
    font-weight: 600;
    font-size: 2em;
    margin-bottom: 9px;
}

.card-small {
    height: 58VH;
}

h3 {
font-size: 23px;
}

.users {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titulo-metricas{
    padding-top: 32px;
    
}

.number{
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
}

.container-retos {
    display: flex;
    flex-wrap: wrap ;
}

.container-retos > div {
    padding: 12px;
    width: 40%;
    margin-right: 24px;
    height: 24vh;
}

.pictures-mas-vistos {
    border: 6px #b3ec50 solid;
    border-radius: 50%;
    width: 80%;
}

.mas-vistos {
    display: flex;
    flex-wrap: wrap;
}

.numbers-mas-vistos {
    margin: 5px 0 0 0;
    font-size: 25px;
    font-weight: bold;
    color:  #686767;
}

.contaners-hijos-retos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jk {
    margin: 0;
}