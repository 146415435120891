#root > div > div.content > div > div.col-lg-9.up_content > div > div.rounded_cont.contents.shadow.col-sm-6 > div > div {
    margin:2rem;
}
#root > div > div.content > div > div.col-lg-9.up_content > div > div.rounded_cont.contents.shadow.col-sm-6 > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div,#root > div > div.content > div > div.col-lg-9.up_content > div > div.rounded_cont.contents.shadow.col-sm-6 > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div {
    padding-left:10px;
    padding-right:10px;
}
#statUsers .moduloStat .progress{
    height: 1.7rem;
    border-radius: 0.6rem;
}
#statUsers .moduloStat .progress .bg-success{
    background-image: linear-gradient(to right, #67B030 , #B4EC51);
}
#statUsers .moduloStat .progress .bg-danger{
    background-image: linear-gradient(to right, #A70B21 , #D33345);
}

#statUsers .row.moduloStat .row, #statUsers .row.masInfo .row{
    display: flex;
    align-items: center;
}
#statUsers .row.moduloStat .nameStat, #statUsers .row.masInfo .nameStat {
    font-size: 1rem;
    font-weight:bolder;
}
#statUsers .row.moduloStat .numStat, #statUsers .row.masInfo .numStat{
    font-size: 2rem;
    font-weight:bolder;
    color: #5C5C5C;
}
#statUsers .row.masInfo .dcha{
 float:right;
 padding-right:10px;
}
#analyticUp {
    margin: 5px;
}
#analyticUp .rounded_cont.contents.shadow.col{
    padding:20px;
    margin: 5px;
}
/* .fichaCurso {
    margin-bottom: 15px;
    box-shadow:1px;
} */
.rotateFlecha {
    transform: rotate(90deg);
}
.infoAprobados {
    margin-bottom: 10px;
    font-size: 0.9rem;
    margin-top:-10px;
    padding-left:25px;
}

.filterRol{
    max-width: 340px;
    /* max-height: 20px; */
}
input.form-control.list-search{
    height: 65px !important;
}