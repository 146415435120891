
.authPage {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 170px 0;
    background: #FAFAFB;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
  }
  
  .wrapper {
    height: 221px;
    width: 685px;
    text-align: center;
    /* background-image: url('../../images/background_empty.svg'); */
    background-position-x: center;
    font-size: 14px;
  }
  
  .errorsContainer {
    margin-top: -21px;
    margin-bottom: 18px;
    color: #ff203c;
  }
  
  .headerContainer {
    line-height: 36px;
    font-size: 24px;
    font-weight: 600;
  }
  
  .headerContainer {
    margin-bottom: 40px;
    height: 73px;
}
  .headerDescription {
    width: 416px;
    text-align: center;
    margin: auto;
    padding: 13px 30px 0 30px;
    line-height: 18px;
    color: #333740;
    font-size: 14px;
  }
  
  .formContainer {
    min-height: 200px;
    width: 416px;
    margin: 14px auto;
    margin-bottom: 0;
    padding: 33px 15px 15px 15px;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 10px 0 #b4cbf1;
  }
  
  .loginButton {
    margin-top: -6px;
    padding-right: 0;
    text-align: right;
  }
  
  .loginButton, button {
    margin-right: 16px;
  }
  
  .buttonContainer {
    margin-top: 20px;
}
  
  .linkContainer, a {
    color: #262931;
  }
  
  .logoContainer {
    position: absolute;
    left: 30px; bottom: 30px;
  }
  
  .logoContainer {
    height: 34px;
  }
  
  .separator {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    background-color: rgba(14, 22, 34, 0.04);
  }
  .accede {
   background-color: #088A68;
   background-image: linear-gradient(-90deg, #088A68, #D8F6CE);
   color: white;
   border-radius: 15px;
   border-style: none;
   height: 3.5em;
  }
  .identi {
   color: #000000;
   font-family: sans-serif;
   font-weight: bold;
  }
  .userimg {
    height: 100%;
    margin: 20px 20px;
}

  .textoacceso {
    text-align: center;
    font-size: 0.9rem;
  }
  .sombra {
    box-shadow: 0 8px 10px 0 #b4cbf1;
  }

  
  .inputText{
    font-family: inherit !important
  }