.registroUser input.inputAvatar {
    box-shadow: none;
}
.col-12.position-relative.form-check{
    margin-bottom: 16px;
}
.col-12.position-relative.form-check .form-check-input {
    box-shadow: none;
    height: auto;
    padding-left:15px;
    margin-left:0px;
}
.col-12.position-relative.form-check .form-check-label {    
    padding-left:15px;   
}
.activo {
    background-image: -webkit-gradient(linear, right top, left top, from(#0994d4), to(#0663af));
    background-image: -webkit-linear-gradient(right, #0994d4 0%, #0663af 100%);
    background-image: -o-linear-gradient(right, #0994d4 0%, #0663af 100%);
    background-image: linear-gradient(-90deg, #0994d4    0%, #0663af 100%);
    -webkit-box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    padding: 10px 10px;
    color: white;
    font-weight: 700;
    -webkit-transition: -webkit-filter 300ms;
    transition: -webkit-filter 300ms;
    -o-transition: filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: default;
}

.boton {
    font-size: 12px;
    width: 33%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}

.bottonera {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    border: 1px solid #DEE1E6;
    border-radius: 100px;
    height: 45px;
    width: 30%;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
.list-searchQuestions {
    padding: 10px 20px 10px 40px;
    background: url(/img/search.png) no-repeat 13px;
    background-size: 20px;
    font-style: italic;
    width: 75%;
    margin-bottom: 10px;
  }

.examentest {
    height: 100px;
    background-size: cover;
    background-position: 50%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.btn-gradient-style{
    background-image: linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175));
    }
    
.examentest.añade {    
    cursor: pointer;    
}
.examentest div{
    line-height: 20px;
    height: 8vh;
    width: 79%;
    text-align: center;
    border-radius: 5px;
    padding: 15px 0px;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.examentest div span{ 
    color: white;
    display: inline-block;
    vertical-align: middle;    
}
.examentest div span.galeria{    
    font-weight: bolder;
    font-size: 1em;
    padding: 0;
}
.examentest div div.reto-title{ 
    font-size: 0.7em;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
.quitaquestion {
    position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
    color: black !important;
}
.col-3.retocont {
    overflow: hidden;
}
.retocont div div .galeria{
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    max-width: none;
    left: 0;
    padding: 10px;
    background-color: #007bff !important;
    
}
